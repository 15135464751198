/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import * as React from 'react';
import { graphql } from 'gatsby';
import { useTranslation } from 'react-i18next';

// Lib UI components
import { Container, Row, Col } from 'react-grid-system';

// Local UI components
import PageLayout from '../shared/PageLayout';
import Seo from '../shared/Seo';
import Hero from '../page-components/SmartTunisia/Hero';
import Content from '../page-components/SmartTunisia/Content';
import CardInfo from '../shared/UIKit/CardInfo';
import VisitationSection from '../shared/UIKit/VisitationSection';

// Style
import '../page-styles/SmartTunisia.scss';

/* -------------------------------------------------------------------------- */
/*                                    Page                                    */
/* -------------------------------------------------------------------------- */

function SmartTunisia() {
  /* ********************************** HOOKS ********************************* */

  // Localization
  const { t } = useTranslation();
  const { Needs, Solutions } = t('cardInfos', { returnObjects: true });

  /* ******************************** RENDERING ******************************* */
  return (
    <PageLayout>
      <Seo title="Smart tunisia" />
      <Hero />
      <Container className="smart-tunisia-light-bg" fluid>
        <Content />
      </Container>
      <Container className="smart-tunisia-white-bg" fluid>
        <Container className="smart-tunisia-cards">
          <Row className="smart-tunisia-cards-row" align="stretch">
            <Col lg={6} md={12} sm={12} className="smart-tunisia-cards-col">
              <CardInfo Class="Needs" content={Needs} />
            </Col>
            <Col lg={6} md={12} sm={12}>
              <CardInfo Class="Solutions" content={Solutions} />
            </Col>
          </Row>
        </Container>
      </Container>
      <VisitationSection
        namePage="Smart Tunisia"
        navigation="http://www.smarttunisia.tn"
      />
    </PageLayout>
  );
}

// SmartTunisia.propTypes = {
//   navigation: PropTypes.string.isRequired,
// };

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["SmartTunisia", "Common"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default SmartTunisia;
