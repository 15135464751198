/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Pakcages
import React from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';

// UI lib comoponents
import { Container, Row, Col } from 'react-grid-system';

// Local UI components
import Image from '../../../shared/Image';
import SubTitle from '../../../shared/UIKit/SubTitle';

// Redux actions and selectors

// Helpers & utils

// Style
import './index.scss';
/* -------------------------------------------------------------------------- */
/*                                  Component                                 */
/* -------------------------------------------------------------------------- */

function Content() {
  /* ******************************** CONSTANTS ******************************* */

  /* ************************ ESSENTIAL LOCAL VARIABLES *********************** */

  /* ********************************** HOOKS ********************************* */

  // State
  const { t } = useTranslation();
  const { firstSection, secondSection } = t('smartTunisia', {
    returnObjects: true,
  });

  /* ***************************** LOCAL VARIABLES **************************** */

  /* ******************************** CALLBACKS ******************************* */

  /* ***************************** RENDER HELPERS ***************************** */
  function FlattenText({ content }) {
    return content.map((object) => {
      if (object.type === 'LIST') {
        return (
          <ul key={object.id} className="smart-tunisia-content-list">
            {object.items.map((item) => (
              <li key={item.id}>{item.text}</li>
            ))}
          </ul>
        );
      }

      return (
        <p key={object.id} className="smart-tunisia-content__paragraph">
          {object.text}
        </p>
      );
    });
  }

  /* ******************************** RENDERING ******************************* */

  return (
    <Container className="smart-tunisia-content smart-tunisia-content-container scroll-reveal">
      <Row justify="center">
        <SubTitle>{firstSection.title}</SubTitle>
      </Row>
      <Row align="start" className="smart-tunisia-content-row">
        <Col lg={6} md={12} sm={12} className="smart-tunisia-content-col">
          <FlattenText content={firstSection.content} />
        </Col>
        <Col
          lg={6}
          md={12}
          sm={12}
          className="smart-tunisia-content-col smart-tunisia-content-col__img"
        >
          <Image src="smart-tunisia/support.png" alt="" aria-hidden="true" />
        </Col>
      </Row>
      <SubTitle className="smart-tunisia-content-section__title">
        {secondSection.title}
      </SubTitle>
      <Row
        align="start"
        className="smart-tunisia-content-row smart-tunisia-content-row--reversed"
      >
        <Col lg={6} md={12} sm={12} className="smart-tunisia-content-col">
          <FlattenText content={secondSection.content} />
        </Col>
        <Col
          lg={6}
          md={12}
          sm={12}
          className="smart-tunisia-content-col smart-tunisia-content-col__img"
        >
          <Image src="smart-tunisia/missions.png" alt="" aria-hidden="true" />
        </Col>
      </Row>
    </Container>
  );
}

Content.propTypes = {};

Content.defaultProps = {};

export default Content;
