/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Pakcages
import React from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';
// Lib UI components
import { Container } from 'react-grid-system';
// Style
import './index.scss';
// Assets
import LOGO from '../../../images/smart-tunisia/logo.svg';
import BackgroundShape from '../../../images/smart-tunisia/smart-tunisia-bg-shape.svg';

/* -------------------------------------------------------------------------- */
/*                                  Component                                 */
/* -------------------------------------------------------------------------- */

function Hero() {
  /* ******************************** CONSTANTS ******************************* */

  /* ************************ ESSENTIAL LOCAL VARIABLES *********************** */

  /* ********************************** HOOKS ********************************* */
  // Localization
  const { t } = useTranslation();
  const { hero } = t('smartTunisia', { returnObjects: true });

  /* ***************************** LOCAL VARIABLES **************************** */

  /* ******************************** CALLBACKS ******************************* */

  /* ***************************** RENDER HELPERS ***************************** */
  function BackgroundShapes() {
    return (
      <>
        <img
          src={BackgroundShape}
          alt=""
          className="smart-tunisia-hero__background-shape smart-tunisia-hero__background-shape--1"
        />
        <img
          src={BackgroundShape}
          alt=""
          className="smart-tunisia-hero__background-shape smart-tunisia-hero__background-shape--2"
        />
        <img
          src={BackgroundShape}
          alt=""
          className="smart-tunisia-hero__background-shape smart-tunisia-hero__background-shape--3"
        />
      </>
    );
  }

  /* ******************************** RENDERING ******************************* */

  return (
    <Container className="smart-tunisia-hero scroll-reveal" fluid>
      <img
        src={LOGO}
        alt="logo-smart-tunisia"
        className="smart-tunisia-hero__logo"
      />
      <BackgroundShapes />
      <p className="smart-tunisia-hero__description">{hero.description}</p>
    </Container>
  );
}

Hero.propTypes = {};

Hero.defaultProps = {};

export default Hero;
