/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Pakcages
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
// Style
import './index.scss';
// Assets
import NEEDSICON from '../../../images/CardInfo/needs.svg';
import SOLUTIONSICON from '../../../images/CardInfo/solution.svg';
/* -------------------------------------------------------------------------- */
/*                                  Component                                 */
/* -------------------------------------------------------------------------- */

function CardInfo({ Class, content }) {
  /* ******************************** CONSTANTS ******************************* */

  /* ********************************** HOOKS ********************************* */

  // Other hooks like reux or localization ...
  const { t } = useTranslation();
  const { Needs, Solutions } = t('cardInfo', { returnObjects: true });

  /* ***************************** RENDER HELPERS ***************************** */
  // eslint-disable-next-line operator-linebreak
  const ImageIcon =
    Class === 'Needs' ? (
      <img src={NEEDSICON} alt="needs-Icon" />
    ) : (
      <img src={SOLUTIONSICON} alt="solution-Icon" />
    );

  function Paragraphs() {
    return content.map(({ id, text }) => <p key={id}>{text}</p>);
  }
  /* ******************************** RENDERING ******************************* */

  return (
    <div className={`${Class === 'Needs' ? 'Needs' : 'solutions'} card-info`}>
      <div>
        {ImageIcon}
        <h3>{Class === 'Needs' ? Needs : Solutions}</h3>
      </div>
      <Paragraphs />
    </div>
  );
}

CardInfo.propTypes = {
  Class: PropTypes.string.isRequired,
  content: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
};

export default CardInfo;
